<template>
  <div id="PersonalCenter" class="wrap">
    <van-nav-bar :fixed="true" :title="$t('Personal.title')" left-arrow placeholder @click-left="onClickLeft" />
    <div class="content">
      <div class="content_item" :class="{ state: NavIndex == index }" v-for="(item, index) in Label" :key="index"
        @click="MousedownEvent(index, item.url)" v-show="!(verConfig.mineShowWallet && item.url === 'walletList')">
        <div class="item_left">
          <van-image class="img" :style="{ flexShrink: 0 }" round :src="item.img" />
          <span>{{ $t(item.text) }}</span>
        </div>
        <div class="item_right">
          <van-image v-if="index == 0" class="img" :style="{ flexShrink: 0 }" round :src="getImage(userInfo[item.image]) ||
      require('../../assets/Img/myImg/MyOne.png')
      " />
          <span v-if="item.span">
            {{ userInfo[item.span] }}
          </span>
          <van-icon name="arrow" size="20" color="#1F1F1F" />
        </div>
      </div>
    </div>
    <!-- <div class="logout" @click="logoutEvent">
      {{ userInfo.token ? $t("My.logout") : $t("newAdd.Login") }}
    </div> -->
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  Toast,
  Dialog,
} from "vant";
import { mapGetters } from 'vuex';
import { userinfo } from '@/api/use.js'
import { cookie } from "@/utils/tools"

export default {
  name: "PersonalCenter",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast,
  },
  data() {
    this.Label = [
      {
        id: "1",
        // img:require('@/assets/Img/myImg/MyOne.png'),
        img: require("@/assets/icon/myInfo/avatar.png"),
        image: "userimg",
        url: "Portrait",
        text: `Personal.Page.Avatar`,
      },
      {
        id: "2",
        // img:require('@/assets/Img/myImg/MyTwo.png'),
        img: require("@/assets/icon/myInfo/edt.png"),
        span: "username",
        // span: this.userInfo && this.userInfo.username,
        url: "Name",
        text: `Personal.Page.Nickname`,
      },
      {
        id: "3",
        // img:require('@/assets/Img/myImg/MyThree.png'),
        img: require("@/assets/icon/myInfo/phone.png"),
        span: "userphone",
        url: "/phone",
        banEdit: true,
        // text: `Personal.Page.PhoneNumber`,
        text: 'Personal.Page.phone'
      },
      {
        id: "4",
        // img:require('@/assets/Img/myImg/MyThree.png'),
        img: require("@/assets/icon/myInfo/mail.png"),
        span: "email",
        banEdit: true,
        url: "/email",
        text: `Personal.Page.email`,
      },
      {
        id: "4",
        // img:require('@/assets/Img/myImg/MyFour.png'),
        img: require("@/assets/icon/myInfo/bank.png"),
        // url: "add-bank-card",
        url: "/bank",
        text: `login.bankInformationLink`,
      },
      {
        id: "5",
        // img:require('@/assets/Img/myImg/MyFive.png'),
        img: require("@/assets/icon/myInfo/pwd.png"),
        url: "/editpasword",
        text: `login.changeLoginPassword`,
      },
      // {
      //   id: "6",
      //   img: require('@/assets/icon/myInfo/pwd.png'),
      //   url: "PaymentCode",
      //   text: `Personal.Page.TransactionPassword`,
      // },
      // {
      //     id:"7",
      //     img:require('@/assets/Img/myImg/MyFive.png'),
      //     url:"Site",
      //     text:`Personal.Page.ReceivingAddress`,
      // },
    ];
    return {
      userInfo: {},
      NavIndex: -1, // 控制选中状态的
    };
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1);
    },
    // 标签鼠标按下
    MousedownEvent(num, url) {
      let _this = this;
      this.NavIndex = num;
      const item = this.Label[num];
      setTimeout(function () {
        _this.NavIndex = -1;
      }, 150);
      if (url != "") {
        if (item.banEdit) {
          if (!!this.userInfo[item.span]) return Toast(this.$t("banEditUserInfo"));
        }
        this.$router.push(url);
      }
    },
    // 注销的按钮
    logoutEvent() {
      let _this = this;
      if (this.userInfo && _this.userInfo.token) {
        Dialog.confirm({
          title: this.$t("My.quit"),
          message: this.$t("My.logoutEvent"),
          cancelButtonText: this.$t("My.Button.One"),
          confirmButtonText: this.$t("My.Button.Two"),
        })
          .then(() => {
            let pwdState = _this.$cookie.get("the-pwd-state");
            let pwdObj = _this.$cookie.get("the-pwd-obj");
            _this.$cookie.clear();
            _this.$cookie.set("the-pwd-state", pwdState);
            _this.$cookie.set("the-pwd-obj", pwdObj);
            _this.$router.push("/login");
            // window.location.reload()
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push("/login");
      }
    },
    // 获取用户信息
    async getUserInfo() {
      const {
        data: { ret, data }
      } = await userinfo()
      if (ret === 1) {
        this.userInfo = data
        cookie.set('userInfo', JSON.stringify(data))
      }
    },
    getImage(url) {
      const reg = new RegExp("http");
      if (reg.test(url)) {
        return url;
      }
      return this.verConfig.url + url;
    },
  },
  created() {
    this.getUserInfo();
    // if(this.module.PersonalEvnet()[1].data.player.icon!=""){
    //     this.Label[0].image = this.module.PersonalEvnet()[1].data.player.icon;
    // }else{
    //     this.Label[0].image = require('@/assets/Img/myImg/head.png')
    // }
    // this.Label[1].span = this.module.PersonalEvnet()[1].data.player.nickname
    // this.Label[2].span = this.module.PersonalEvnet()[0].data.info.phone
  },
  // computed: {
  //   ...mapGetters(["userInfo"])
  // }
};
</script>

<style lang="less" scoped>
#PersonalCenter {
  width: 100%;
  height: 100%;
  background-color: #fff;

  /deep/ .van-nav-bar__content {
    background-color: #ff0000;

    .van-icon-arrow-left {
      color: #fff;
    }

    .van-nav-bar__title {
      color: #fff;
    }
  }

  .content {
    background-color: #fff;

    &_item {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      span {
        color: #1F1F1F;
      }

      .item_left {
        display: flex;
        align-items: center;
        justify-content: center;

        .img {
          width: 50px;
          height: 50px;
        }

        span {
          font-size: 32px;
          margin-left: 20px;
        }
      }

      .item_right {
        display: flex;
        align-items: center;
        justify-content: center;

        .img {
          width: 80px;
          height: 80px;
        }

        span {
          font-size: 28px;
          margin-right: 20px;
        }
      }
    }

    &_item::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      border-bottom: 1px solid #e1cccc;
    }
  }
}
</style>